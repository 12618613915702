@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .snap-y {
    scroll-snap-type: y mandatory;
  }
  .snap-start {
    scroll-snap-align: start;
  }
  .snap-always {
    scroll-snap-stop: always;
  }
  .text-outline-black {
    text-shadow: -1px -1px 0 #000000, 1px -1px 0 #000000, -1px 1px 0 #000000,
      1px 1px 0 #000000;
  }
  .text-outline-white {
    text-shadow: -1px -1px 0 #fcfcee, 1px -1px 0 #fcfcee, -1px 1px 0 #fcfcee,
      1px 1px 0 #fcfcee;
  }
  .text-outline-green {
    text-shadow: -1px -1px 0 #848717, 1px -1px 0 #848717, -1px 1px 0 #848717,
      1px 1px 0 #848717;
  }
  .text-outline-red {
    text-shadow: -1px -1px 0 #991616, 1px -1px 0 #991616, -1px 1px 0 #991616,
      1px 1px 0 #991616;
  }
  .text-outline-gold {
    text-shadow: -1px -1px 0 #d19a10, 1px -1px 0 #d19a10, -1px 1px 0 #d19a10,
      1px 1px 0 #d19a10;
  }
  .text-outline-gray {
    text-shadow: -1px -1px 0 #808080, 1px -1px 0 #808080, -1px 1px 0 #808080,
      1px 1px 0 #808080;
  }
  .text-outline-darkgray {
    text-shadow: -1px -1px 0 #dadada, 1px -1px 0 #dadada, -1px 1px 0 #dadada,
      1px 1px 0 #dadada;
  }
  .animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }

  @keyframes pulse {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
  .perspective {
    perspective: 1000px;
  }

  .rotate-y-180 {
    transform: rotateY(180deg);
  }

  .transform-gpu {
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
  }
}
